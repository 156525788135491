import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The story of Obie — a bot revolutionizing Learning Management Solutions.`}</p>
    <h2><figure className="wp-caption"><figcaption className="wp-caption-text"></figcaption></figure>{`SUMMARY`}</h2>
    <p><a parentName="p" {...{
        "href": "https://obie.ai/"
      }}>{`Obie`}</a>{` is a Slack bot that provides workplace information to employees. Carolyn Chong, Head of Product at Obie, shares how they use `}<a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`Dashbot`}</a>{`’s conversational analytics to increase onboarding conversion by 40% and improve user experience by analyzing conversational transcripts.`}</p>
    <h2><strong parentName="h2">{`CHALLENGES`}</strong></h2>
    <p>{`One of the toughest challenges bots face is teaching users how to communicate with them. As Obie’s newly appointed Head of Product, Carolyn made it a goal to onboard users seamlessly.`}</p>
    <p>{`While at their previous companies prior to creating Obie, co-founders Ravinda Seunath, Alex Sopinka, and Chris Buttenham wanted access to an internal company knowledge base that has common information that most employees look for and need, such as holiday schedule, expense codes, HR benefits, etc. They found that it was particularly challenging to locate an internal wiki and when they finally did stumble upon it, they realized that sharing knowledge within it was not an option.`}</p>
    <p>{`The rise of bots and conversational interfaces was a lightbulb moment for these founders. They did not want to create yet another tool that required internal training, so conversational interfaces gave way to a new method of delivering information and ultimately, an enterprise solution.`}</p>
    <p>{`Obie helps companies access multiple information sources across teams on Slack by answering questions with keywords that include: Who, What, Where, When, and How. Obie has become another person on the team and someone people actually want to go to for information, rather than a dead Learning Management Solution (LMS).`}</p>
    <h2><strong parentName="h2">{`HOW DASHBOT HELPED`}</strong></h2>
    <p>{`The most important fact to remember about bots is that they provide a one-to-one communication channel with your customer. Customers are able and willing to tell you exactly what they think about brands or products using their own words, emojis, and gifs. With `}<a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`Dashbot`}</a>{` analytics, you’re able to gain insight into all of these interactions using Conversation Transcripts.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Carolyn would scour the Conversation Transcripts on a daily basis to read and analyze exactly what users were saying to Obie. By reading transcripts, Carolyn was able to identify the messages that Obie could not answer and understand which responses would create user frustration.`}</p>
    </blockquote>
    <p>{`In addition to the turnkey analytics solution provided by Dashbot, Carolyn remarks that, “The primary benefit that pushed Obie’s decision to choose Dashbot as our conversational analytics provider was the personalized customer support as we continue to build and grow Obie.”`}</p>
    <h2><strong parentName="h2">{`THE RESULTS`}</strong></h2>
    <p>{`After iterating their user onboarding experience, in less than three months, the total number of core actions increased by over 40%. Originally, onboarding was simply account creation, but they did away with that after investigating user behavior and responses and improved onboarding with the following iterations:`}</p>
    <ol>
      <li parentName="ol">{`Rather than displaying a list of everything Obie could do for you, Obie now shows you what it can accomplish by walking you through core functionality.`}</li>
      <li parentName="ol">{`They added the option to onboard via web interface for user comfortability. After onboarding, they would drop users into Obie for an `}<em parentName="li">{`aha moment`}</em>{` and a reward for setting up their account by showing users what Obie can do with the information they provided.`}</li>
      <li parentName="ol">{`In the first version of onboarding, the 3 core questions that Obie could address were limited to What, Where, and How. After reading transcripts, they expanded Obie’s abilities to answer Who and When because they saw that top user inquiries included these keywords.`}</li>
    </ol>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2017/08/06214904/a4885-1iv5dv02jkv4bv5fhxramyw.png",
        "alt": null
      }}></img><br parentName="p"></br>{`
`}{`Old version of onboarding`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2017/08/06214903/125ea-1tmj-aelxak0zc46uhp32ba.png",
        "alt": null
      }}></img><br parentName="p"></br>{`
`}{`New version of onboarding`}</p>
    <p>{`Need an upgrade for your internal wiki? Add `}<a parentName="p" {...{
        "href": "https://obie.ai/"
      }}>{`Obie`}</a>{` to your team’s Slack channels and make their lives easier.`}</p>
    <hr></hr>
    <h2>{`About Dashbot`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/"
      }}>{`Dashbot`}</a>{` is a bot analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable data and tools.`}</p>
    <p>{`You can view detailed analytics `}{`&`}{` competitive market metrics, analyze conversation transcripts, track message funnels, optimize referral campaigns, understand sentiment `}{`&`}{` demographics, and add human-in-the-loop to your bot — all in real time.`}</p>
    <p>{`We natively support Facebook Messenger, Twitter, Amazon Alexa, Google Home, Slack, and Kik and have a generic API for any conversational interface. Sign up for free at `}<a parentName="p" {...{
        "href": "https://www.dashbot.io"
      }}>{`www.dashbot.io`}</a>{`.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales?ref="
      }}>{`Contact us for a demo`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      